import ClippingNewsCollection from "@sections/clipping-news-collection/component";
import EmptySpace from "@components/empty-space/component";
import FullBleed from "@components/full-bleed";
import Headline from "@components/headline/headline-component";
import Layout from "@components/layout";
import Newsletter from "@components/newsletter/newsletter-component";
import React from "react";
import Seo from "@components/seo";

export default function Imprensa() {
  return (
    <Layout isHeroAvailable={false}>
      <EmptySpace mobile={{ margin: 80 }} />

      <FullBleed>
        <Headline
          title="A treebo também é notícia"
          description="Acompanha aqui as ações da treetree2, conforme são vistas pelos olhos
          de quem a observa."
          bgColor="#e43f5a"
          imageFilename="pexels-cottonbro"
        />
      </FullBleed>

      <EmptySpace desktop={{ margin: 150 }} mobile={{ margin: 80 }} />

      <ClippingNewsCollection />

      <EmptySpace desktop={{ margin: 150 }} mobile={{ margin: 60 }} />

      <FullBleed>
        <Newsletter />
      </FullBleed>
      <Seo
        title="TreeTree2 na imprensa"
        description="Consulta aqui o resumo das principais notícias dos órgãos de comunicação social que envolvem o TreeTree2."
      />
    </Layout>
  );
}
