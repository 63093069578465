export const data = [
  {
    author: "Fonte",
    imageFilename: "ll7",
    title: "Título da Notícia",
    href: "https://www.google.com/",
  },
  {
    author: "Fonte",
    imageFilename: "ll7",
    title: "Título da Notícia",
    href: "https://www.google.com/",
  },
  {
    author: "Fonte",
    imageFilename: "e33",
    title: "Título da Notícia",
    href: "https://www.google.com/",
  },
  {
    author: "Fonte",
    imageFilename: "rt3",
    title: "Título da Notícia",
    href: "https://www.google.com/",
  },
  {
    author: "Fonte",
    imageFilename: "h8h",
    title: "Título da Notícia",
    href: "https://www.google.com/",
  },
  {
    author: "Fonte",
    imageFilename: "a2a",
    title: "Título da Notícia",
    href: "https://www.google.com/",
  },
  {
    author: "Fonte",
    imageFilename: "ll7",
    title: "Título da Notícia",
    href: "https://www.google.com/",
  },
  {
    author: "Fonte",
    imageFilename: "e33",
    title: "Título da Notícia",
    href: "https://www.google.com/",
  },
  {
    author: "Fonte",
    imageFilename: "rt3",
    title: "Título da Notícia",
    href: "https://www.google.com/",
  },
  {
    author: "Fonte",
    imageFilename: "h8h",
    title: "Título da Notícia",
    href: "https://www.google.com/",
  },
];
